import React, { Fragment, useState, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import { CloseOutlined } from '@ant-design/icons'
import {addMag, deleteMag, getAllMag} from "../Api/Mag"
import API_URL_IMG from '../config/confingImg';

function Mag() {
  let [magData, setMagData] = useState([])

  const saveFormData = (e) => {
      e.preventDefault();

      const { magImg, magTitle, magDesc } = e.target;

      let image_as_file = magImg.files[0]

      addMag(image_as_file, magTitle.value, magDesc.value)
      .then(() => {
        getAllMagData()
      })
  }

    const getAllMagData = () => {
      getAllMag().then(response => {
          setMagData(response)
      })
    }

    const deleteMagFunc = (magID) => {
      deleteMag(magID)
      .then(() => {
        getAllMagData()
      })
    }

    useEffect(() => {
      getAllMagData()
    }, [])

  return (
    <Fragment>
    <Navbar/>
    <Sidebar/>
    <div className="DuplicateClass">
      <div className="Slider">
        <h4>AJOUTER VOTRE PRODUIT :</h4>
        <hr />
        <div>
          <div className="rowFlex ">
            {magData.map((el, i) => {
              return (
                <div className="border m-5 p-4 MagCart" key={i}>
                  <Fragment>
                    <img className="imageSlider" src={`${API_URL_IMG}/${el.image}`} alt="" />
                    <p className='mt-2 mb-5'>{el.title}</p>
                    <p className='mt-2 mb-5'>{el.description}</p>
                    <button class="btn btn-danger" onClick={() => deleteMagFunc(el._id)}><CloseOutlined /> Suprimer</button>
                  </Fragment>
                </div>
              )
            })}
          </div>
        </div>
        <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn btn-success add"><span>+</span>AJOUTER</button>
      </div>
      {/*Modal */}
      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <form onSubmit={saveFormData}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Ajouter votre artist</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body p-5">
                <input type="file" className="form-control mt-5" name="magImg"></input>
                <label className="mt-5">Title</label>
                <input type="text" className="form-control" name="magTitle"></input>
                <label className="mt-5">Description</label>
                <textarea className="form-control" name="magDesc" />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary text-white" data-dismiss="modal">Fermer</button>
                <button type="submit" className="btn btn-primary">Enregistrer</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </Fragment>
  )
}

export default Mag
