import API_URL from '../config/config';
const axios = require('axios');

export const addSlider = (sliderImg, txt) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    // let sliderObject = {
    //     sliderImg: sliderImg,
    //     txt: txt
    // }

    let formData = new FormData();
    formData.append('sliderImg', sliderImg)
    formData.append('txt', txt)

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.post(`${API_URL}/slider/addSliderImg/${adminID}`, formData, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteSlider = (sliderID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${API_URL}/slider/${sliderID}/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getAllSlider = () => {
    return axios.get(`${API_URL}/slider/getSliderImg`)
    .then(res => res.data)
    .catch(err => console.log(err))
}