import API_URL from '../config/config';
const axios = require('axios');

export const getAllUsers = () => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.get(`${API_URL}/admin/allUsers/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const disableEnableUser = (userID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return axios.put(`${API_URL}/admin/disableEnableUser/${adminID}/${userID}`, {}, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteUser = (userID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return axios.delete(`${API_URL}/admin/${adminID}/${userID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}
