import React, { useState, Fragment, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import { CloseOutlined } from '@ant-design/icons'
import {addProgrammation, deleteProgrammation, getAllProgrammation} from "../Api/Programmation"
import API_URL_IMG from '../config/confingImg';

function Programmation() {
    let [programmationrData, setProgrammationrData] = useState([])

    const saveFormData = (e) => {
        e.preventDefault();

        const { artistImg, artistName, presentTime } = e.target;

        let image_as_file = artistImg.files[0]

        addProgrammation(image_as_file, artistName.value, presentTime.value)
        .then(() => {
            artistName.value = ""
            presentTime.value = ""
            getAllProgrammationData()
        })
    }

    const getAllProgrammationData = () => {
        getAllProgrammation().then(response => {
            setProgrammationrData(response)
        })
    }

    const deleteProgrammationFunc = (sliderID) => {
    deleteProgrammation(sliderID)
    .then(() => {
        getAllProgrammationData()
    })
    }

      useEffect(() => {
        getAllProgrammationData()
      }, [])

    return (
        <Fragment>
        <Navbar/>
        <Sidebar/>
        <div className="DuplicateClass">
            <div className="Slider">
                <h4>AJOUTER VOTRE PRODUIT :</h4>
                <hr />
                <div>
                    <div className="rowFlex" style={{}}>
                        {programmationrData.map((el, i) => {
                            return (
                                <div className="colFlex text-center border m-5">
                                    <img className="imageSlider" src={`${API_URL_IMG}/${el.image}`} />
                                    <strong>
                                        <p className="mt-4">{el.artistName}</p>
                                    </strong>
                                    <p>{el.presentTime}</p>
                                    <button class="btn btn-danger" onClick={() => deleteProgrammationFunc(el._id)}><CloseOutlined /> Suprimer</button>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn btn-success add"><span>+</span>AJOUTER</button>
            </div>
            {/*Modal */}
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

                <form onSubmit={saveFormData}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ajouter votre artist</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body p-5">
                                <label className="">L'artist :</label>
                                <input type="text" className="form-control" name="artistName" />
                                <label className="">Date :</label>
                                <input type="datetime-local" className="form-control" name="presentTime" />
                                <input type="file" className="form-control mt-5" name="artistImg"></input>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary text-white" data-dismiss="modal">Fermer</button>
                                <button type="submit" className="btn btn-primary">Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </Fragment>
    )
}

export default Programmation