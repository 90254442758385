import React, { Fragment, useState, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import {getAllUsers, deleteUser, disableEnableUser} from "../Api/Users"

function CV() {
  let [usersData, setUsersData] = useState([])

  const getAllUserData = () => {
    getAllUsers().then(response => {
      setUsersData(response)
    })
  }

  const disableUserFunc = (ID) => {
    disableEnableUser(ID)
    .then(() => {
      getAllUserData()
    })
  }

  const deleteUserInfoFunc = (ID) => {
    deleteUser(ID)
    .then(() => {
      getAllUserData()
    })
  }

  useEffect(() => {
    getAllUserData()
  }, [])

  return (
    <Fragment>
    <Navbar/>
    <Sidebar/>
    <div className="DuplicateClass">
    <div className="Slider">
    <h4>Users :</h4>
    <hr />
        <div>
          <table id="dtDynamicVerticalScrollExample" className="table table-striped table-bordered table-sm" cellspacing="0"
            width="100%">
            <thead>
              <tr>
                <th className="th-sm">Nom & Prénom</th>
                <th className="th-sm">fonctionalité</th>
                <th className="th-sm">telephone</th>
                <th className="th-sm">email</th>
                <th className="th-sm">ville</th>
                <th className="th-sm">Crée à</th>
                <th className="th-sm">Activation</th>
                <th className="th-sm">Suprimer</th>
              </tr>
            </thead>
            <tbody>
              {usersData.map((el, i) => {
                return (
                  <tr>
                    <td>{el.firstLastName}</td>
                    <td>{el.fonctionality.map(el => (<p>{el}</p>))}</td>
                    <td>{el.phoneNumber}</td>
                    <td>{el.email}</td>
                    <td>{el.city}</td>
                    <td>{el.created_time}</td>
                    <td><button onClick={() => disableUserFunc(el._id)}>{!el.isActive ? "Activer" : "Disactiver"}</button></td>
                    <td><button onClick={() => deleteUserInfoFunc(el._id)}>Suprimer</button></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

    </div>
    </Fragment>
  )
}

export default CV
