import React, { Fragment, useState, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import {getAllUserNewsLetter, deleteUserNewsLetter} from "../Api/newsLetter"

function NewsLetter() {
  let [NewsLetterData, setNewsLetterData] = useState([])

  const getAllNewsLetterData = () => {
    getAllUserNewsLetter().then(response => {
      setNewsLetterData(response)
    })
  }

  const deleteNewsLetter = (newsLetterID) => {
    deleteUserNewsLetter(newsLetterID)
    .then(() => {
      getAllNewsLetterData()
    })
  }

  useEffect(() => {
    getAllNewsLetterData()
  }, [])

  return (
    <Fragment>
    <Navbar/>
    <Sidebar/>
    <div className="DuplicateClass">
    <div className="Slider">
    <h4>AJOUTER VOTRE PRODUIT :</h4>
    <hr />
        <div>
          <table id="dtDynamicVerticalScrollExample" className="table table-striped table-bordered table-sm" cellspacing="0"
            width="100%">
            <thead>
              <tr>
                <th className="th-sm">Nom
                </th>
                <th className="th-sm">Prénom
                </th>
                <th className="th-sm">Email
                </th>
                <th className="th-sm">Recevoir
                </th>
                <th className="th-sm">Recevoir
                </th>

              </tr>
            </thead>
            <tbody>
              {NewsLetterData.map((el, i) => {
                return (
                  <tr>
                    <td>{el.firstName}</td>
                    <td>{el.lastName}</td>
                    <td>{el.email}</td>
                    <td>{el.allNewsLetter ? "Recevoir toutes les actualités de l’événement." : "Recevoir les newsletters de L’HOLI."}</td>
                    <td><button onClick={() => deleteNewsLetter(el._id)}>Suprimer</button></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

    </div>
    </Fragment>
  )
}

export default NewsLetter