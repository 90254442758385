import React, { useState, Fragment, useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import {addSlider, deleteSlider, getAllSlider} from "../Api/Slider"
import API_URL_IMG from '../config/confingImg';

function Slider(props) {
  // const sliderData = [
  //   { id: 1, image: "../assets/sliderImg3.jpg", desc: 'Notre organisation et ses partenaires stockent et/ou accèdent à des informations sur votre appareil, telles que les identifiants uniques de cookies pour traiter les données personnelles. Vous pouvez accepter ou gérer vos choix en cliquant ci-dessous, y compris votre droit d’opposition en cas d’utilisation d’un intérêt légitime, ou à tout moment sur la page de politique de confidentialité. Ces préférences seront signalées à nos partenaires et n’affecteront pas les données de navigation. ' },
  //   { id: 2, image: "../assets/sliderImg3.jpg", desc: 'Notre organisation et ses partenaires stockent et/ou accèdent à des informations sur votre appareil, telles que les identifiants uniques de cookies pour traiter les données personnelles. Vous pouvez accepter ou gérer vos choix en cliquant ci-dessous, y compris votre droit d’opposition en cas d’utilisation d’un intérêt légitime, ou à tout moment sur la page de politique de confidentialité. Ces préférences seront signalées à nos partenaires et n’affecteront pas les données de navigation.' }
  // ]

  let [sliderData, setSliderData] = useState([])

  const saveFormData = (e) => {
    e.preventDefault();

    const { sliderImg, sliderTxt } = e.target;

    let image_as_file = sliderImg.files[0]

    addSlider(image_as_file, sliderTxt.value)
    .then(() => {
      getAllSliderData()
    })
  }

  const getAllSliderData = () => {
    getAllSlider().then(response => {
      setSliderData(response)
    })
  }

  const deleteSliderFunc = (sliderID) => {
    deleteSlider(sliderID)
    .then(() => {
      getAllSliderData()
    })
  }

  useEffect(() => {
    getAllSliderData()
  }, [])

  return (
    <Fragment>
    <Navbar />
    <Sidebar />
    <div className="DuplicateClass">
    <div className="Slider">
      <h4>AJOUTER VOTRE PRODUIT :</h4>
      <hr />
      <div>
        <div className="rowFlex ">
          {sliderData.map((el, i) => {
            return (
              <Fragment>
                <div className="border m-5 p-4">
                  <p><img className="imageSlider" src={`${API_URL_IMG}/${el.image}`} /></p>
                  <p className='mt-2 mb-5'>{el.txt}</p>
                  <button class="btn btn-danger" onClick={() => deleteSliderFunc(el._id)}><CloseOutlined /> Suprimer</button>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
      <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn btn-success add"><span>+</span>AJOUTER</button>
    </div>
    {/*Modal */}
    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <form onSubmit={saveFormData}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ajouter votre artist</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-5">
              <input type="file" id="image" className="form-control mt-5" name='sliderImg'></input>
              Description:
              <textarea type="" id="desc" class="form-control" name="sliderTxt" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary text-white" data-dismiss="modal">Fermer</button>
              <button type="submit" className="btn btn-primary">Enregistrer</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
    </Fragment>
  )
}

export default Slider
