import API_URL from '../config/config';
const axios = require('axios');

export const signIn = (email, password) => {
    let adminObject = {
        email: email,
        password: password
    }

    return axios.post(`${API_URL}/admin/signIn`, adminObject)
    .then(res => {
        return res.data
    })
    .catch(err => console.error(err))
}

export const isAuthenticated = () => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    if(!adminID || !token)
        return false

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.get(`${API_URL}/admin/${adminID}`, config)
    .then(res => true)
    .catch(err => {
        sessionStorage.clear()
    })
}
