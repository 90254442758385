import React, { useState, Fragment, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import {addTransport, getAllTransport, deleteTransport, getOneTransport, updateTransport} from "../Api/Transport"
import { Modal } from 'antd';

function Transport() {
  let [depart, setDepartArray] = useState([{depart: ""}])
  let [retour, setRetourArray] = useState([{retour: ""}])
  let [listOfTransport, setListOfTransport] = useState([])

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transportData, setTransportData] = useState({station: "", depart: [], retour: [], ID: ""})

  const handleDeleteDepartList = (index) => {
    let newArr = [...transportData.depart]
    newArr.splice(index, 1)

    setTransportData(prevState => ({
      ...prevState, depart: newArr
    }))
  }

  const handleDeleteRetourList = (index) => {
    let newArr = [...transportData.retour]
    newArr.splice(index, 1)

    setTransportData(prevState => ({
      ...prevState, retour: newArr
    }))
  }

  const handlAddNewDeparPoint = () => {
    let newArr = [...transportData.depart]
    newArr.push("")

    setTransportData(prevState => ({
      ...prevState, depart: newArr
    }))
  }

  const handlAddNewRetourPoint = () => {
    let newArr = [...transportData.retour]
    newArr.push("")

    setTransportData(prevState => ({
      ...prevState, retour: newArr
    }))
  }

  const modifyTransportFunc = (transportID) => {
    setVisible(true);

    getOneTransport(transportID).then(response => {
      setTransportData({station: response.station, depart: response.depart, retour: response.retour, ID: response._id})
    })
  }

  const handleDepartPoint = (index, e) => {
    let newArr = [...transportData.depart]
    newArr[index] = e.target.value

    setTransportData(prevState => ({
      ...prevState, depart: newArr
    }))
  }

  const handleTitleChange = (e) => {
    const { name, value } = e.target;

    setTransportData(prevState => ({
      ...prevState, [name]: value
    }))
  }

  const handleRetourPoint = (index, e) => {
    let newArr = [...transportData.retour]
    newArr[index] = e.target.value

    setTransportData(prevState => ({
      ...prevState, retour: newArr
    }))
  }

  const getTransportData = () => {
    getAllTransport().then(response => {
      setListOfTransport(response)
    })
  }

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      // updateBilletterie(billetterieMFormData.title, billetterieMFormData.remarque, billetterieMFormData.price, billetterieMFormData.isActivated, billetterieMFormData.dateAndTime, billetterieMFormData.descriptionList, billetterieMFormData.ID, billetterieMFormData.isSoldOut).then(() => {
      //   alert("form is submited successfully")
      // })
      updateTransport(transportData.station, transportData.depart, transportData.retour, transportData.ID).then(() => {
        
      }).then(() => {
        getTransportData()
      })
    }, 2000)
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  const saveFormData = (e) => {
    e.preventDefault()
    let departArray = depart.map(element => element.depart)
    let retourArray = retour.map(element => element.retour)

    const { station } = e.target;

    addTransport(station.value, departArray, retourArray).then(response => {
      station.value = ""
      departArray = ""
      retourArray = ""

      setDepartArray([{depart: ""}])
      setRetourArray([{retour: ""}])
    })
    .then(() => {
      getTransportData()
    })
  }

  let handleAddInputD = (e) => {
    e.preventDefault()
    setDepartArray([...depart, {depart: ""}])
  }

  let handleAddInputA = (e) => {
    e.preventDefault()
    setRetourArray([...retour, {retour: ""}])
  }

  let modifyRetour = (index, event) => {
    const { name, value } = event.target;
    const list = [...retour];
    list[index][name] = value;
    setRetourArray(list)
  }

  let modifyDepart = (index, event) => {
    const { name, value } = event.target;
    const list = [...depart];
    list[index][name] = value;
    setDepartArray(list)
  }

  let deleteOneDepart = (index, event) => {
    let data = [...depart];
    data.splice(index, 1)
    setDepartArray(data)
  }

  let deleteOneRetour = (index, event) => {
    let data = [...retour];
    data.splice(index, 1)
    setRetourArray(data)
  }

  useEffect(() => {
    getTransportData()
  }, [])

  const deleteTransportFunc = (transportID) => {
    deleteTransport(transportID)
    .then(() => {
      getTransportData()
    })
  }

  return (
    <Fragment>
      <Navbar />
      <Sidebar />
      <div className="DuplicateClass">
        <div className="Slider">
          <h4>AJOUTER VOTRE PRODUIT :</h4><span>        <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn btn-success add"><span>+</span>AJOUTER</button>
          </span>
          <hr />
          <div>
            <table id="dtDynamicVerticalScrollExample" className="table table-striped table-bordered table-sm" cellspacing="0"
              width="100%">
              <thead>
                <tr>
                  <th className="th-sm">Station
                  </th>
                  <th className="th-sm">Depart
                  </th>
                  <th className="th-sm">Retour
                  </th>
                  <th className="th-sm">Suprimer
                  </th>
                  <th className="th-sm">Modifier
                  </th>

                </tr>
              </thead>
              <tbody>

                {listOfTransport.map((el, i) => {
                  return (
                    <tr>
                      <td>{el.station}</td>
                      <td>{el.depart.map(element => <p>{element}</p>)}</td>
                      <td>{el.retour.map(element => <p>{element}</p>)}</td>
                      <td> <button onClick={() => deleteTransportFunc(el._id)}>Suprimer</button> </td>
                      <td> <button onClick={() => modifyTransportFunc(el._id)}>Modifier</button> </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>
        </div>
        {/*Modal */}
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">

            <form onSubmit={saveFormData}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Ajouter votre artist</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body p-5">
                  <label className="mt-5">Station :</label>
                  <input className="form-control" name='station' />
                  <label className="mt-5">Depart :</label>
                  <div className="col">

                  {depart.map((element,index) => {
                    const {depart}= element;
                    return (
                      <div className='descriptionList'>
                      <input type="text" className="form-control"  onChange={event => modifyDepart(index, event)} key={index} name="depart" value={depart} />
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={event => deleteOneDepart(index, event)}><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                      </div>
                    )
                  })}

                    <button className="btn btn-primary" onClick={handleAddInputD}>+</button>
                  </div>
                  <label className="mt-5">Retour :</label>

                  {retour.map((element, index) => {
                    const {retour} = element;
                    return (
                      <div className='descriptionList'>
                        <input type="text" className="form-control"  onChange={event => modifyRetour(index, event)} key={index} name="retour" value={retour} />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={event => deleteOneRetour(index, event)}><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                      </div>
                    )
                  })}

                  <button className="btn btn-primary" onClick={handleAddInputA}>+</button>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary text-white" data-dismiss="modal">Fermer</button>
                  <button type="submit" className="btn btn-primary">Enregistrer</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>


      <Modal
        title="Title"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >

                <div className="modal-body">
                  <label className="mt-5">Station :</label>
                  <input className="form-control" name='station' value={transportData.station} onChange={handleTitleChange} />
                  <label className="mt-5">Depart :</label>
                  <div className="col">

                  {transportData.depart.map((element,index) => {
                    return (
                      <div className='descriptionList'>
                      <input type="text" className="form-control" value={element} key={index} onChange={e => handleDepartPoint(index, e)} name="depart" />
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={() => handleDeleteDepartList(index)}><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                      </div>
                    )
                  })}

                    <button className="btn btn-primary" onClick={handlAddNewDeparPoint}>+</button>
                  </div>
                  <label className="mt-5">Retour :</label>

                  {transportData.retour.map((element, index) => {
                    return (
                      <div className='descriptionList'>
                        <input type="text" className="form-control" value={element} key={index} onChange={e => handleRetourPoint(index, e)} name="retour" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={() => handleDeleteRetourList(index)}><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                      </div>
                    )
                  })}

                  <button className="btn btn-primary" onClick={handlAddNewRetourPoint}>+</button>
                </div>

          {/* {JSON.stringify(transportData)} */}

      </Modal>
    </Fragment>
  )
}

export default Transport