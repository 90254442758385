import API_URL from '../config/config';
const axios = require('axios');

export const addProgrammation = (artistImg, artistName, presentTime) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    // let sliderObject = {
    //     sliderImg: sliderImg,
    //     txt: txt
    // }

    let formData = new FormData();
    formData.append('artistImg', artistImg)
    formData.append('artistName', artistName)
    formData.append('presentTime', presentTime)

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.post(`${API_URL}/programmation/addImg/${adminID}`, formData, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteProgrammation = (sliderID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${API_URL}/programmation/${sliderID}/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getAllProgrammation = () => {
    return axios.get(`${API_URL}/programmation/getProgrammationImg`)
    .then(res => res.data)
    .catch(err => console.log(err))
}