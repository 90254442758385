import API_URL from '../config/config';
const axios = require('axios');

export const addTransport = (station, depart, retour) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    let transportObject = {
        station: station,
        depart: depart,
        retour: retour
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.post(`${API_URL}/transport/add/${adminID}`, transportObject, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteTransport = (transportID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${API_URL}/transport/${transportID}/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getAllTransport = () => {
    return axios.get(`${API_URL}/transport/allTransport`)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getOneTransport = (transportID) => {
    return axios.get(`${API_URL}/transport/${transportID}`)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const updateTransport = (station, depart, retour, stationID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    let stationObject = {
        station: station,
        depart: depart,
        retour: retour,
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.put(`${API_URL}/transport/${stationID}/${adminID}`, stationObject, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}
