import API_URL from '../config/config';
const axios = require('axios');

export const addBilletterie = (title, descriptionList, remarque, price, dateLimite) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    let billetterieObject = {
        title: title,
        descriptionList: descriptionList,
        remarque: remarque,
        price: parseFloat(price),
        dateLimite: dateLimite
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.post(`${API_URL}/belletterie/${adminID}`, billetterieObject, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const updateBilletterie = (title, remarque, price, isActivated, dateLimite, descriptionList, belletterieID, isSoldOut) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    let billetterieObject = {
        title: title,
        remarque: remarque,
        price: price,
        isActivated: isActivated,
        dateLimite: dateLimite,
        descriptionList: descriptionList,
        isSoldOut: isSoldOut
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.put(`${API_URL}/belletterie/${belletterieID}/${adminID}`, billetterieObject, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteBilletterie = (belletterieID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${API_URL}/belletterie/${belletterieID}/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getAllBilletterie = () => {
    return axios.get(`${API_URL}/belletterie`)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getOneBilletterie = (belletterieID) => {
    return axios.get(`${API_URL}/belletterie/${belletterieID}`)
    .then(res => res.data)
    .catch(err => console.log(err))
}
