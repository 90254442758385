import React, {useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import {isAuthenticated} from "../Api/SignInSignUp"

function Navbar() {
    let history = useHistory();

    const logoutFunc = () => {
        sessionStorage.clear()
    }

    useEffect(() => {
        if(!isAuthenticated())
            history.push("/Login");
    })

    return (
        <div class="Navbar row">
            <div class="location col-6">
                <h1 class="Title">L'Holi</h1>
            </div>
            <div class="SeConnecter col-6 text-right">
            <Link to="/Login">
                <button onClick={logoutFunc}>Se déconnecter</button>
            </Link>
            </div>
        </div>
    )
}

export default Navbar