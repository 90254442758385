import { React } from 'react'
// import { BrowserRouter as Router, Routes , Route } from 'react-router-dom'
import { BrowserRouter, Switch, Route, Navigate, useLocation, Redirect } from 'react-router-dom'

import Login from './Auth/Login'
import Slider from './Components/Slider'
import Programmation from './Components/Programmation'
import Mag from './Components/Mag'
import Newsletter from './Components/Newsletter'
import PacksBilletterie from './Components/PacksBilletterie'
import Transport from './Components/Transport'
import CV from './Components/CV'
import UsersForm from "./Components/UsersForm"
import Users from "./Components/Users"
import Classement from "./Components/Classement"

function Routs() {
    return (
        <BrowserRouter>

        <Switch >
               {/* <Route exact path="/Login" component={Login} />*/}
               <Route exact path="/Transport" component={Transport}/>
               <Route exact path="/PacksBilletterie" component={PacksBilletterie}/>
               <Route exact path="/Newsletter" component={Newsletter}/>
               <Route exact path="/Mag" component={Mag}/>
               <Route exact path="/" component={Slider}/>
               <Route exact path='/Login' component={Login}/>
               <Route exact path="/Programmation" component={Programmation}/>
               <Route exact path="/CV" component={CV}/>
               <Route exact path="/UsersForm" component={UsersForm}/>
               <Route exact path="/Users" component={Users}/>
               <Route exact path="/Classement" component={Classement}/>
            </Switch>
        </BrowserRouter>
    )
}
export default Routs
