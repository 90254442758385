import React from 'react'
import { Link } from 'react-router-dom'
import { BgColorsOutlined } from '@ant-design/icons'
import logo from '../assets/hovredLogo.png'
import { useLocation } from 'react-router-dom'
function Navbar() {
    const { pathname } = useLocation();

    return (
        <div className="Sidebar">
            <div className="divLogo">
                <img src={logo} />
            </div>
            <div className="SideBareMenu">
                <div class="Link">
                    <Link to="/">
                        <button  class={ pathname == '/' ? 'classActive linkMenu row ' : 'linkMenu row ' } >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Slider</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/Programmation">
                        <button class={ pathname == '/Programmation' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Programmation</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to='/Mag'>
                        <button class={ pathname == '/Mag' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Le Mag</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/Newsletter">
                        <button class={ pathname == '/Newsletter' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Newsletter</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/PacksBilletterie">
                        <button class={ pathname == '/PacksBilletterie' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Packs billetterie</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/Transport">
                        <button class={ pathname == '/Transport' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Transport</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/CV">
                        <button class={ pathname == '/CV' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>formulaire</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/UsersForm">
                        <button class={ pathname == '/UsersForm' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>users form</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/Users">
                        <button class={ pathname == '/Users' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>users</span>
                            </div>
                        </button></Link>
                </div>
                <div class="Link">
                    <Link to="/Classement">
                        <button class={ pathname == '/Classement' ? 'classActive linkMenu row ' : 'linkMenu row ' }  >
                            <div class="div1 col-6">
                                <BgColorsOutlined class="iconButton" />
                            </div>
                            <div class="div2 col-6">
                                <span>Classement</span>
                            </div>
                        </button></Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar

