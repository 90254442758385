import API_URL from '../config/config';
const axios = require('axios');

export const addMag = (artistImg, title, description) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    let formData = new FormData();
    formData.append('artistImg', artistImg)
    formData.append('description', description)
    formData.append('title', title)

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.post(`${API_URL}/MAG/addMagImg/${adminID}`, formData, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteMag = (sliderID) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${API_URL}/MAG/${sliderID}/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const getAllMag = () => {
    return axios.get(`${API_URL}/MAG/getMagImg`)
    .then(res => res.data)
    .catch(err => console.log(err))
}