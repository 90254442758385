import React, { Fragment, useState, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import {getAllBidHistory} from "../Api/Classement"

function CV() {
  let [usersData, setUsersData] = useState([])

  const getAllBidData = () => {
    getAllBidHistory().then(response => {
      setUsersData(response)
    })
  }

  useEffect(() => {
    getAllBidData()
  }, [])

  return (
    <Fragment>
    <Navbar/>
    <Sidebar/>
    <div className="DuplicateClass">
    <div className="Slider">
    <h4>Classement :</h4>
    <hr />
        <div>
          <table id="dtDynamicVerticalScrollExample" className="table table-striped table-bordered table-sm" cellspacing="0"
            width="100%">
            <thead>
              <tr>
                <th className="th-sm">Participants</th>
                <th className="th-sm">Email</th>
                <th className="th-sm">Somme</th>
                <th className="th-sm">Date</th>
              </tr>
            </thead>
            <tbody>
              {usersData.map((el, i) => {
                return (
                  <tr>
                    <td>{el.from_user.firstLastName}</td>
                    <td>{el.from_user.email}</td>
                    <td>{el.amount}</td>
                    <td>{el.created_time}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

    </div>
    </Fragment>
  )
}

export default CV
