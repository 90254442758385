import React, { Fragment, useState, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import {getAllUserCV, deleteCV} from "../Api/Cv"
import API_URL_IMG from "../config/confingImg"

function CV() {
  let [cvData, setCvData] = useState([])

  const getAllCVData = () => {
    getAllUserCV().then(response => {
      setCvData(response)
    })
  }

  const deleteCvFunc = (cvID) => {
    deleteCV(cvID)
    .then(() => {
      getAllCVData()
    })
  }

  const downloadCV = (cvUrl) => {
    window.open(`${API_URL_IMG}/${cvUrl}`, '_blank');
  }

  useEffect(() => {
    getAllCVData()
  }, [])

  return (
    <Fragment>
    <Navbar/>
    <Sidebar/>
    <div className="DuplicateClass">
    <div className="Slider">
    <h4>AJOUTER VOTRE PRODUIT :</h4>
    <hr />
        <div>
          <table id="dtDynamicVerticalScrollExample" className="table table-striped table-bordered table-sm" cellspacing="0"
            width="100%">
            <thead>
              <tr>
                <th className="th-sm">Nom
                </th>
                <th className="th-sm">Prénom
                </th>
                <th className="th-sm">phone
                </th>
                <th className="th-sm">birthDate</th>
                <th className="th-sm">sexe</th>
                <th className="th-sm">adresseMail</th>
                <th className="th-sm">etablisement</th>
                <th className="th-sm">niveau d'étude</th>
                <th className="th-sm">why subcribe</th>
                <th className="th-sm">why interested</th>
                <th className="th-sm">CV</th>
                <th className="th-sm"></th>
              </tr>
            </thead>
            <tbody>
              {cvData.map((el, i) => {
                return (
                  <tr>
                    <td>{el.firstname}</td>
                    <td>{el.lastname}</td>
                    <td>{el.phone}</td>
                    <td>{el.birthDate}</td>
                    <td>{el.sexe}</td>
                    <td>{el.adresseMail}</td>
                    <td>{el.etablisement}</td>
                    <td>{el.degree}</td>
                    <td style={{wordBreak: "break-word"}}>{el.whyDesirToSubcribe}</td>
                    <td>{el.whyDoYouIntersted.map(el => <p>{el}</p>)}</td>
                    <td><button onClick={() => downloadCV(el.yourCV)}>télécharger</button></td>
                    <td><button onClick={() => deleteCvFunc(el._id)}>supprimer</button></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

    </div>
    </Fragment>
  )
}

export default CV
