import React,{useState} from 'react'
import { Input, Button } from 'antd';
import { UserOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import logo from "../assets/hovredLogo.png"
import API_URL from '../config/config';
const axios = require('axios');

function Login(props) {
  const [user, setUser] = useState({ email: '', password: '' })
 
  const handleChange = event => {
    setUser({ ...user, [event.target.id]: event.target.value });
  }
  const submitSignIn = e => {
      e.preventDefault();
      axios.post(`${API_URL}/admin/signIn`, user)
          .then((res) => {
              props.history.push('/');
              sessionStorage.setItem("tokenUser",res.data.admin.token)
              sessionStorage.setItem("idUser",res.data.admin._id)

              res.json();
          })
          .catch(err => console.log((err)))
  }
  return (
    <form onClick={submitSignIn}>
      <div className="Login">
        <div>
          <div className="divImage text-center">
            <img src={logo} alt='' />
          </div>
          <div className="divLogin text-center">
            <div>
              <h5>LOGIN</h5>
            </div>
            <div>
              <Input className="InputLogin" size="large" placeholder="Username" prefix={<UserOutlined />} 
              onChange={handleChange} id="email" 
              />
            </div>
            <div>
              <Input className="InputLogin" size="large" placeholder="Password" prefix={<SafetyCertificateOutlined />} 
              onChange={handleChange} id="password"
              />
            </div>
            <div>
              <Button className="btn" type="primary">Connexion</Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Login
