import React, { useState,Fragment, useEffect } from 'react'
import Navbar from '../includes/Navbar'
import Sidebar from '../includes/Sidebar'
import { CloseOutlined } from '@ant-design/icons'
import {addBilletterie, getAllBilletterie, deleteBilletterie, getOneBilletterie, updateBilletterie} from "../Api/Billetterie"
import { Modal, Button, Switch } from 'antd';

function PacksBilletterie() {
  let [listOfDescription, setListOfDescription] = useState([{fullName: ""}])
  let [listOfBilletterie, setlistOfBilletterie] = useState([])
  // let [oneBilletterieData, setOneBilletterieData] = useState([])

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [billetterieMFormData, setBilletterieMFormData] = useState({title: "", remarque: "", price: "", isActivated: Boolean, dateAndTime: "", descriptionList: [], isSoldOut: Boolean});

  const handleUpdatedFormValue = (e) => {
    const { name, value } = e.target;

    setBilletterieMFormData(prevState => ({
      ...prevState, [name]: value
    }))
  }

  const handleUpdatedFormValueCheck = () => {
    setBilletterieMFormData(prevState => ({
      ...prevState, isActivated: !billetterieMFormData.isActivated
    }))
  }

  const handleUpdatedFormValueCheckSoldOut = () => {
    setBilletterieMFormData(prevState => ({
      ...prevState, isSoldOut: !billetterieMFormData.isSoldOut
    }))
  }

  const handleBilletterieDescriptionValue = (index, e) => {
    let newArr = [...billetterieMFormData.descriptionList]
    newArr[index] = e.target.value

    setBilletterieMFormData(prevState => ({
      ...prevState, descriptionList: newArr
    }))
  }

  const handleBilletterieDeletedDescriptionValue = (index) => {
    let newArr = [...billetterieMFormData.descriptionList]

    newArr.splice(index, 1)

    setBilletterieMFormData(prevState => ({
      ...prevState, descriptionList: newArr
    }))
  }

  const handleBilletterieAddDescriptionValue = () => {
    let newArr = [...billetterieMFormData.descriptionList]

    newArr.push("")

    setBilletterieMFormData(prevState => ({
      ...prevState, descriptionList: newArr
    }))
  }

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);

      updateBilletterie(billetterieMFormData.title, billetterieMFormData.remarque, billetterieMFormData.price, billetterieMFormData.isActivated, billetterieMFormData.dateAndTime, billetterieMFormData.descriptionList, billetterieMFormData.ID, billetterieMFormData.isSoldOut).then(() => {
        alert("form is submited successfully")
      })
      .then(() => {
        getBelletterieData()
      })
    }, 2000)
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  let handleAddInput = (e) => {
    e.preventDefault()
    setListOfDescription([...listOfDescription, {fullName: ""}])
  }

  let modifyDescription = (index, event) => {
    const { name, value } = event.target;
    const list = [...listOfDescription];
    list[index][name] = value;
    setListOfDescription(list)
  }

  let deleteOneDescription = (index, event) => {
    let data = [...listOfDescription];
    data.splice(index, 1)
    setListOfDescription(data)
  }

  const saveFormData = (e) => {
    e.preventDefault()
    let listOfDescriptionArray = listOfDescription.map(element => element.fullName)

    const { title, remarque, price, dateLimite } = e.target;

    addBilletterie(title.value, listOfDescriptionArray, remarque.value, price.value, dateLimite.value).then(response => {
      title.value = ""
      remarque.value = ""
      price.value = ""
      dateLimite.value = ""

      setListOfDescription([{fullName: ""}])
    })
    .then(() => {
      getBelletterieData()
    })
  }

  const getBelletterieData = () => {
    getAllBilletterie().then(response => {
      setlistOfBilletterie(response)
    })
  }

  const deleteOneBelletterie = (BelletterieID) => {
    deleteBilletterie(BelletterieID).then(() => {
      getBelletterieData()
    })
  }

  const modifyOneBelletterie = (BelletterieID) => {
    setVisible(true);

    getOneBilletterie(BelletterieID).then(response => {
      setBilletterieMFormData({title: response.title, remarque: response.remarque, price: response.price, isActivated: response.isActivated, ID: response._id, dateAndTime: response.dateLimite, descriptionList: response.descriptionList, isSoldOut: response.isSoldOut})
    })
  }

  useEffect(() => {
    getBelletterieData()
  }, [])

  return (
    <Fragment>
    <Navbar/>
    <Sidebar/>
    <div className="DuplicateClass">
      <div className="Slider">
        <hr  />
        <div>
          {listOfBilletterie.map((el, i) => {
            return (
              <Fragment>
              <p><span className="liPacksBilletterie">Title:</span> {el.title} </p>              
              <p>
              <span className="liPacksBilletterie">Description:</span>
                <ul>
                {el.descriptionList.map(e => <li>{e}</li>)}
                </ul>
              </p>              
              <p><span className="liPacksBilletterie">remarque:</span> {el.remarque} </p>
              <p><span className="liPacksBilletterie">Price:</span> {el.price} </p>
                <button class="btn btn-danger m-3" onClick={() => deleteOneBelletterie(el._id)}><CloseOutlined /> Suprimer</button>
                {/* <button class="btn btn-warning m-3" onClick={() => modifyOneBelletterie(el._id)}><CloseOutlined /> Modifier</button> */}
                <Button type="primary" onClick={event => modifyOneBelletterie(el._id)}>
                  Modifier
                </Button>
               <hr/>
              </Fragment>
            )
          })}
        </div>
        <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn btn-success add"><span>+</span>AJOUTER</button>
      </div>
      {/*Modal */}
      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <form className="modal-content" onSubmit={saveFormData}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ajouter votre artist</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-5">
            <label className="mt-5">Title :</label>
            <input className="form-control" name="title" />
            <label className="mt-5">Description :</label>
            <div>
              {listOfDescription.map((element, index) => {
                const {fullName}= element;
                return (
                  <div className='descriptionList'>
                    <input type="text" className="form-control" placeholder="description" onChange={event => modifyDescription(index, event)} key={index} name="fullName" value={fullName}  />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={event => deleteOneDescription(index, event)}><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                  </div>
                )
              }
              )}
              <button className="btn btn-primary" onClick={handleAddInput}>+</button>
            </div>

            <label className="mt-5">Remarque :</label>
            <input className="form-control" name="remarque" />
            <label className="mt-5">Price :</label>
            <input className="form-control" name="price" />

            <label className="mt-5">Date limite :</label>
            <input className="form-control" type="datetime-local" placeholder="dd/mm/yyyy --:-- --" name="dateLimite" />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary text-white" data-dismiss="modal">Fermer</button>
              <button type="submit" className="btn btn-primary">Enregistrer</button>
            </div>
          </form>
        </div>
      </div>
    </div>

      <Modal
        title="Title"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >

        <div>
          <label className="mt-5">Title :</label>
          <input className="form-control" name="title" value={billetterieMFormData.title} onChange={handleUpdatedFormValue} />
          <label className="mt-5">Remarque :</label>
          <input className="form-control" name="remarque" value={billetterieMFormData.remarque} onChange={handleUpdatedFormValue} />
          <label className="mt-5">Price :</label>
          <input className="form-control" name="price" value={billetterieMFormData.price} onChange={handleUpdatedFormValue} />
          <label className="mt-5">Date limite :</label>
          <input className="form-control" type="datetime-local" placeholder="dd/mm/yyyy --:-- --" name="dateAndTime" value={billetterieMFormData.dateAndTime} onChange={handleUpdatedFormValue} />

          <label className="mt-5">description :</label>
          <div>
              {billetterieMFormData.descriptionList.map((element, index) => {
                return (
                  <div className='descriptionList'>
                    <input type="text" className="form-control" placeholder="description" key={index} value={element} name="descriptionList" onChange={e => handleBilletterieDescriptionValue(index, e)}  />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={() => handleBilletterieDeletedDescriptionValue(index)}><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
                  </div>
                )
              }
              )}
              <button className="btn btn-primary" onClick={handleBilletterieAddDescriptionValue}>+</button>
          </div>

          <label className="mt-5">active :</label> <br />
          <Switch checked={billetterieMFormData.isActivated} name="isActivated" onChange={handleUpdatedFormValueCheck} /> <br />

          <label className="mt-5">solde out :</label> <br />
          <Switch checked={billetterieMFormData.isSoldOut} name="isSoldOut" onChange={handleUpdatedFormValueCheckSoldOut} /> <br />

          {/* {JSON.stringify(billetterieMFormData)} */}

        </div>
      </Modal>
    </Fragment>
  )
}

export default PacksBilletterie

