import API_URL from '../config/config';
const axios = require('axios');

export const getAllUserForm = () => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.get(`${API_URL}/userform/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

export const deleteUserForm = (CvId) => {
    const adminID = sessionStorage.getItem('idUser')
    const token = sessionStorage.getItem('tokenUser')

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${API_URL}/userform/${CvId}/${adminID}`, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

